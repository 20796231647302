import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './Navbar.scss';
import logo from '../../../images/logo2.png';

const MIN_SCROLL_POS = 10;
const SCROLL_DELTA = 5;

const Navbar = ({ closeMenu, scrollMyContainer }) => {
  const [show, setShow] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    const newScrollPos = Math.abs(document.body.getBoundingClientRect().top);
    // If the scroll is smaller than the SCROLL_DELTA, we do nothing
    if (Math.abs(newScrollPos - scrollPos) <= SCROLL_DELTA && newScrollPos > 0) {
      return;
    }

    const newShow = newScrollPos < scrollPos || newScrollPos <= MIN_SCROLL_POS;
    if (show && !newShow) {
      closeMenu();
    }

    setShow(newShow);
    setScrollPos(newScrollPos);

    scrollMyContainer();
  };

  const handleBurgerClick = () => {
    const navbar = document.getElementById('nav');
    const burger = document.getElementById('navBurger');
    const menu = document.getElementById('navMenu');
    // Toggle the "is-active" className on both the "navbar-burger" and the "navbar-menu"
    [navbar, burger, menu].forEach((elem) =>
      elem.classList.toggle('is-active')
    );

    const isActive = burger.classList.contains('is-active');
    if (isActive) {
      document.documentElement.classList.add('locked-scroll');
      document.getElementById('main').classList.add('blur');
    } else {
      document.documentElement.classList.remove('locked-scroll');
      document.getElementById('main').classList.remove('blur');
    }
  };

  useEffect(() => {
    // Mount function
    const main = document.getElementById('main');
    main.addEventListener('scroll', handleScroll);

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    );

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) =>
        el.addEventListener('click', handleBurgerClick)
      );
    }

    // Unmount function
    return () => {
      main.removeEventListener('scroll', handleScroll);

      if ($navbarBurgers.length > 0) {
        // Remove the click event on each of them
        $navbarBurgers.forEach((el) =>
          el.removeEventListener('click', handleBurgerClick)
        );
      }
    };
  });

  return (
    <nav id="nav" className="navbar is-dark is-fixed-top is-sticky scrolled-in">
      <div className="navbar-brand">
        <figure className="image is-64x64">
          <img className="is-radius-16" src={logo} alt=""/>
        </figure>
        <div className="navbar-start">
          <div className="navbar-item has-text-white is-size-6-mobile navbar-title">
            <span className="is-family-hakio">L</span>
            <span className="is-family-osake">'</span>
            <span className="is-family-hakio">ame d</span>
            <span className="is-family-osake">'</span>
            <span className="is-family-hakio">un pirate</span>
          </div>
        </div>
        <span id="navBurger" role="button" className="navbar-burger burger" data-target="navMenu" aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>
      <div id="navMenu" className="navbar-menu has-background-dark">
        <div className="navbar-end">
          <Link className="navbar-item" to="/" activeClassName='has-text-info'>
            Qui suis je ?
          </Link>
          <Link className="navbar-item" to="/contact" activeClassName='has-text-info'>
            Me contacter
          </Link>
          <Link className="navbar-item" to="/faq" activeClassName='has-text-info'>
            FAQ
          </Link>
        </div>
      </div>
      <div className="progress-container">
        <div className="progress-bar" id="progressBar"></div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  scrollMyContainer: PropTypes.func.isRequired,
};

export default Navbar;
